<template>
    <transition name="fade">
      <div v-if="showPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div class="max-w-sm w-full space-y-8 p-6 bg-white rounded-lg shadow-md">
          <div>
            <h2 class="text-center text-3xl font-extrabold text-gray-900">
              Confirm Trade
            </h2>
          </div>
          <form class="space-y-6" @submit.prevent="confirmTrade">
            <p>Are you sure you want to <span class="font-bold" :class="{'text-green-500': tradeType === 'buy', 'text-red-500': tradeType === 'sell'}">{{ tradeType.toUpperCase() }}</span> {{ tradeVolume }} lots of {{ tradeSymbol }}?</p>
            
            <div class="flex items-center justify-between space-x-4">
              <button type="button" class="flex-1 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="closePopup">
                Cancel
              </button>
              <button type="submit" class="flex-1 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      showPopup: Boolean,
      tradeType: String,
      tradeSymbol: String,
      tradeVolume: Number
    },
    methods: {
      confirmTrade() {
        this.$emit('confirm-trade', { type: this.tradeType, symbol: this.tradeSymbol, volume: this.tradeVolume });
      },
      closePopup() {
        this.$emit('close-popup');
      }
    }
  };
  </script>
  
  <style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  