<template>
      <div class="tradingview-widget-container">
      <div class="tradingview-widget-container__widget"></div>
      <div class="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span class="blue-text">Track all markets on TradingView</span></a> by TradingView</div>
    </div>
</template>

<script>
export default{
    mounted() {
        const widgetPlaceholder = document.getElementsByClassName('tradingview-widget-container')[0];
widgetPlaceholder.replaceChildren(); // empty placeholder
const script = document.createElement('script');
script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js'
script.async = true;
script.innerHTML = JSON.stringify({
  "symbols": [
    [
      "FPMARKETS:BTCUSD|1M|USD"
    ]
  ],
 "chartOnly": false,
  "width": "100%",
  "height": "100%",
  "locale": "en",
  "colorTheme": "light",
  "autosize": true,
  "showVolume": false,
  "showMA": false,
  "hideDateRanges": false,
  "hideMarketStatus": false,
  "hideSymbolLogo": false,
  "scalePosition": "right",
  "scaleMode": "Normal",
  "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
  "fontSize": "10",
  "noTimeScale": false,
  "valuesTracking": "1",
  "changeMode": "price-and-percent",
  "chartType": "candlesticks",
  "maLineColor": "#2962FF",
  "maLineWidth": 1,
  "maLength": 9,
  "lineWidth": 2,
  "lineType": 0,
  "dateRanges": [
    "1d|5",
    "1m|30",
    "3m|60",
    "12m|1D",
    "60m|1W",
    "all|1M"
]
});
widgetPlaceholder.appendChild(script);
    },


}
</script>