import { createApp } from 'vue';
import App from './App.vue';
import router from './router';  // Import the router configuration
import './assets/tailwind.css';
import Toaster from '@meforma/vue-toaster';

const app = createApp(App);

// Function to check and redirect to the last stored route if within the specified duration
const checkAndRedirectLastRoute = () => {
    const lastRoute = sessionStorage.getItem('lastRoute');
    const lastRouteTime = sessionStorage.getItem('lastRouteTime');
    const currentTime = new Date().getTime();

    const oneHour = 60 * 60 * 1000; // One hour in milliseconds

    if (lastRoute && lastRouteTime && (currentTime - lastRouteTime < oneHour)) {
        router.push(lastRoute);
    } else {
        sessionStorage.removeItem('lastRoute');
        sessionStorage.removeItem('lastRouteTime');
    }
};

// Call the function to check and redirect
checkAndRedirectLastRoute();

app.use(router);  // Use the router
app.use(Toaster, {
    position: 'top-right',
    duration: 5000
});

app.mount('#app');

// Function to clear stored route after the duration
const clearStoredRoute = () => {
    const lastRouteTime = sessionStorage.getItem('lastRouteTime');
    const currentTime = new Date().getTime();
    const oneHour = 60 * 1000; // One hour in milliseconds

    if (lastRouteTime && (currentTime - lastRouteTime >= oneHour)) {
        sessionStorage.removeItem('lastRoute');
        sessionStorage.removeItem('lastRouteTime');
    }
};

// One hour in milliseconds
const oneHour = 60 * 1000;

// Check and clear stored route every hour
setInterval(clearStoredRoute, oneHour);
