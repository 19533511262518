<template>
    <transition name="fade">
      <div v-if="showPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div class="max-w-5xl w-full p-6 bg-white rounded-lg shadow-lg relative">
          <button @click="closePopup" class="absolute top-2 right-2 text-gray-500 hover:text-gray-800">
            <span class="text-2xl">&times;</span>
          </button>
          <h2 class="text-2xl font-semibold mb-4">Select Trading Pairs</h2>
          <div class="flex flex-wrap max-h-96 overflow-y-auto">
            <label v-for="pair in tradingPairs" :key="pair" class="flex items-center space-x-2 mb-2 w-1/4">
              <input type="checkbox" :value="pair" v-model="selectedPairs" class="form-checkbox" />
              <span class="text-gray-800">{{ pair }}</span>
            </label>
          </div>
          <div class="mt-4 flex justify-end">
            <button @click="applySelection" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
              Apply
            </button>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      showPopup: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        selectedPairs: [],
        tradingPairs: [
          "BTCUSD", "SOLUSD", "ETHUSD", "XRPUSD", "LTCUSD", "BCHUSD", "EOSUSD", "ADAUSD",
          "DOTUSD", "DOGEUSD", "UNIUSD", "LINKUSD", "XLMUSD", "VETUSD", "THETAUSD", "TRXUSD",
          "ETCUSD", "FILUSD", "AAVEUSD", "ATOMUSD", "MKRUSD", "AVAXUSD", "ALGOUSD", "ZECUSD",
          "NEOUSD", "DASHUSD", "MANAUSD", "SUSHIUSD", "COMPUSD", "YFIUSD", "KSMUSD", "RENUSD",
          "1INCHUSD", "GRTUSD", "SNXUSD", "CRVUSD", "ENJUSD", "BATUSD", "BALUSD", "SRMUSD"
        ]
      };
    },
    methods: {
      closePopup() {
        this.$emit('close-popup');
      },
      applySelection() {
        this.$emit('apply-selection', this.selectedPairs);
        this.closePopup();
      }
    }
  }
  </script>
  
  <style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  