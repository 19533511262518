<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
    <div class="bg-white rounded-lg shadow-lg p-8 max-w-lg w-full relative">
      <button @click="close" class="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-800">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 class="text-3xl font-bold text-center mb-6">About This Project</h2>
      <div class="text-gray-700 space-y-4">
        <p><strong>Year of Production:</strong> 2024</p>
        <p><strong>Owner:</strong> KOPOWER d.o.o.</p>
        <p><strong>Software Version:</strong> V0.1</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.bg-white {
  background-color: #ffffff;
}
.rounded-lg {
  border-radius: 0.75rem;
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-8 {
  padding: 2rem;
}
.max-w-lg {
  max-width: 32rem;
}
.text-gray-700 {
  color: #4a5568;
}
.text-3xl {
  font-size: 1.875rem;
}
.font-bold {
  font-weight: 700;
}
.text-center {
  text-align: center;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.space-y-4 > :not(:last-child) {
  margin-bottom: 1rem;
}
</style>
