<template>
    <transition name="fade">
        <div v-if="showPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div class="max-w-sm w-full space-y-8 p-6 bg-white rounded-lg shadow-md relative">
                <button class="absolute top-2 right-2 text-gray-500 hover:text-gray-700" @click="closePopup">
                    <span class="text-2xl">&times;</span>
                </button>
                <div>
                    <h2 class="text-center text-3xl font-extrabold text-gray-900">
                        Confirm Bot Kill Switch
                    </h2>
                </div>
                <form class="space-y-6" @submit.prevent="confirmKillSwitch">
                    <p>To stop the bot, please type <strong>"stop"</strong> in the box below:</p>
                    <input type="text" v-model="confirmationText" class="w-full p-2 border border-gray-300 rounded-md" />
                    <div class="flex items-center justify-between space-x-4">
                        <button type="button" class="flex-1 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" @click="closePopup">
                            Cancel
                        </button>
                        <button type="submit" class="flex-1 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" :disabled="confirmationText !== 'stop'">
                            Confirm
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        showPopup: Boolean
    },
    data() {
        return {
            confirmationText: ''
        };
    },
    methods: {
        confirmKillSwitch() {
            if (this.confirmationText === 'stop') {
                this.$emit('confirm-kill-switch');
                this.closePopup();
            }
        },
        closePopup() {
            this.$emit('close-popup');
        }
    }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
