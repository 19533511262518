<template>
    <div>
        <canvas ref="chartCanvas"></canvas>
        <div>
            <button class="bg-gray-500 hover:bg-gray-700 text-white font-semibold text-xs py-2 px-3 mx-1 mt-2 rounded" @click="updateData('All')">All</button>
            <button class="bg-gray-500 hover:bg-gray-700 text-white font-semibold text-xs py-2 px-3 mx-1 mt-2 rounded" @click="updateData('1M')">1M</button>
            <button class="bg-gray-500 hover:bg-gray-700 text-white font-semibold text-xs py-2 px-3 mx-1 mt-2 rounded" @click="updateData('1W')">1W</button>
        </div>
    </div>
</template>

<script setup lang="js">
import { onMounted, ref } from 'vue';
import Chart from 'chart.js/auto';

const chartCanvas = ref(null);
let myChart = null;

onMounted(() => {
    if (chartCanvas.value) {
        myChart = new Chart(chartCanvas.value.getContext('2d'), {
            type: 'line',
            data: {
                labels: [], // Initial empty labels
                datasets: [{
                    label: 'Equity min.',
                    data: [],
                    borderWidth: 2,
                    borderColor: 'rgba(55,65,81, 1)',
                    backgroundColor: 'rgba(55,65,81, 1)'
                },
                {
                    label: 'Equity max.',
                    data: [],
                    borderWidth: 2,
                    borderColor: 'rgba(250,204,21, 1)',
                    backgroundColor: 'rgba(250,204,21, 1)'
                }]
            },
            options: {
                scales: {
                    x: {
                        type: 'linear', // Change x-axis type to linear
                        position: 'bottom',
                        ticks: {
                            callback: function(value, index) {
                                const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                                return labels[index % labels.length];
                            }
                        }
                    },
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: function(tooltipItems) {
                                const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                                return labels[tooltipItems[0].dataIndex % labels.length];
                            }
                        }
                    }
                }
            }
        });
    }
});

function updateData(frame) {
    let newLabels, newDataMin, newDataMax;
    switch (frame) {
        case '1W':
            newLabels = Array.from({length: 14}, (_, i) => i); // 14 data points
            newDataMin = [159.76, 193.04, 170.55, 158.98, 134.73, 179.18, 137.52, 228.35, 242.73, 126.69, 208.35, 155.78, 163.61, 235.12]
            newDataMax = [162.69, 194.07, 165.24, 159.91, 131.36, 186.86, 138.04, 228.99, 238.06, 135.84, 214.89, 157.12, 161.94, 230.13];
            break;
        case '1M':
            newLabels = Array.from({length: 30}, (_, i) => i); // 30 data points
            newDataMin = [159.76, 193.04, 170.55, 158.98, 134.73, 179.18, 137.52, 228.35, 242.73, 126.69, 208.35, 155.78, 163.61, 235.12]
            newDataMax = [162.69, 194.07, 165.24, 159.91, 131.36, 186.86, 138.04, 228.99, 238.06, 135.84, 214.89, 157.12, 161.94, 230.13]
            break;
        case 'All':
            newLabels = Array.from({length: 12}, (_, i) => i); // 12 data points
            newDataMin = [0, 14, 31, 52, 33, 45, 66, 75, 69, 83, 144, 166];
            newDataMax = [10, 24, 41, 100, 43, 55, 76, 85, 79, 93, 244, 266];
            break;
    }

    // Update the chart with new labels and data
    myChart.data.labels = newLabels;
    myChart.data.datasets[0].data = newDataMin;
    myChart.data.datasets[1].data = newDataMax;

    myChart.update();
}
</script>
