<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full relative">
      <button @click="close" class="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-800">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 class="text-2xl font-semibold mb-4 text-center">Forgot Password</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-4">
          <input 
            v-model="email" 
            type="email" 
            placeholder="Email Address" 
            class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            required
          />
        </div>
        <div class="flex justify-center">
          <button type="submit" class="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500">
            Reset Password
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submitForm() {
      const formData = {
        email: this.email
      };
      console.log('Sent data:', formData);
      fetch('https://server.vue.kopower.si/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Password reset failed');
        }
        return response.json();
      })
      .then(data => {
        console.log('Response data:', data);
      })
      .catch(err => {
        console.error(err);
      });
    }
  }
}
</script>

<style scoped>
</style>
