<template>
  <div :class="themeClass" class="min-h-screen flex flex-col items-center justify-center px-2 sm:px-6 lg:px-8">
    
    <!-- Popup component -->
<ConfirmTrade 
  :showPopup="showPopup" 
  :tradeType="tradeType" 
  :tradeSymbol="tradeSymbol" 
  :tradeVolume="tradeVolume"
  @confirm-trade="handleTradeConfirm"
  @close-popup="handlePopupClose"
/>

<ConfirmClose
  :showPopup="showClosePopup"
  :positionId="closingPositionId"
  :positionVolume="closingPositionVolume"
  @confirm-close="handlePositionClose"
  @close-popup="handlePopupCloseTrade"
/>

<ConfirmBotKillSwitch 
    :showPopup="showKillSwitchPopup" 
    @confirm-kill-switch="handleKillSwitchConfirm"
    @close-popup="handleKillSwitchPopupClose"
  />
<!-- Status bar -->
<div v-if="isAuthenticated" :class="themeClass" class="min-h-screen flex flex-col items-center justify-center px-2 sm:px-6 lg:px-8">
<div class="max-w-4xl w-full bg-white text-gray-900 py-2 my-2 shadow-md rounded-lg">
    <div class="max-w-4xl mx-auto flex justify-between items-center px-4 relative">
        <div class="username-display flex items-center space-x-2 relative">
            <img @click="toggleDropdown" src="../assets/menu.svg" alt="Settings" class="h-8 w-8 cursor-pointer" />
            <span class="font-semibold">User:</span>
            <span class="bg-gray-200 py-1 px-2 rounded font-semibold">{{ loggedUser }}</span>
            <transition name="dropdown">
                <div v-if="showDropdown" class="absolute top-full left-0 bg-gray-100 border rounded-md shadow-lg z-10 mt-2">
                    <ul class="">
                        <li @click="showPreferences" class="px-4 py-2 hover:bg-gray-200 cursor-pointer font-semibold">Preferences</li>
                        <li @click="showSupport" class="px-4 py-2 hover:bg-gray-200 cursor-pointer font-semibold">Support</li>
                        <li @click="showSettings" class="px-4 py-2 hover:bg-gray-200 cursor-pointer font-semibold">Settings</li>
                        <li @click="logOut" class="px-4 py-2 hover:bg-gray-200 cursor-pointer font-semibold">Log out</li>
                        <li @click="showAbout" class="px-4 py-2 hover:bg-gray-200 cursor-pointer font-semibold">About</li>
                    </ul>
                    <!--<img src="../assets/Moon.svg" alt="Kill Switch" class="h-8 w-8"/>-->
                    <div class="flex justify-center py-2">
                      
                      <label class="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" v-model="isDarkMode" @change="toggleDarkMode" class="sr-only peer">
                        <div class="w-11 h-6 bg-gray-700 peer-focus:outline-none  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400 dark:peer-checked:bg-yellow-500"></div>
                      </label>

                    </div>
                </div>
            </transition>
        </div>
        <div class="time-display flex items-center space-x-2">
            <span class="font-semibold">Date:</span>
            <span class="bg-gray-200 py-1 px-2 rounded font-semibold">{{ currentDate }}</span>
            <span class="bg-gray-200 py-1 px-2 rounded font-semibold hidden sm:flex">{{ currentTimeOnly }}</span>
        </div>
    </div>
    <PreferencesPage :isVisible="isPreferencesVisible" @close="isPreferencesVisible = false" />
    <SupportPage :isVisible="isSupportVisible" @close="isSupportVisible = false" />
    <SettingsPage :isVisible="isSettingsVisible" @close="isSettingsVisible = false" />
    <AboutPage :isVisible="isAboutVisible" @close="isAboutVisible = false" />
</div>



<!--********************************************************************************************************************************-->
<!-- Bot Settings Box -->
<div class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
  <h2 class="text-left text-3xl font-extrabold text-gray-900 mb-4">
    General Info
  </h2>



  <hr>

  <!-- Main Container Split into Two Vertical Sections -->
  <div class="flex flex-col sm:flex-row mt-2">

    <!-- Left Side -->
    <div class="flex flex-col w-full sm:w-1/3 items-center bg-gray-200 rounded-lg sm:mr-2 shadow-md mb-2 sm:mb-0 p-2">
          <div class="w-full mb-4">
            <h3 class="text-center text-xl font-bold text-gray-900">
          Bot kill switch
        </h3>
      </div>


          <!-- Bot kill switch -->
          <div class="w-full flex justify-center">
            <button @click="sendKillSignal" class="bg-red-600 text-white active:bg-red-800 font-bold uppercase text-2xl px-4 py-4 rounded-full hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" type="button">
              <img src="../assets/power-off-solid.svg" alt="Kill Switch" class="h-16 w-16"/>
            </button>
      </div>
    </div>

    <!-- Status -->
    <div class="flex flex-col w-full sm:w-1/3 items-center bg-gray-200 rounded-lg px-4 shadow-md mb-2 sm:mb-0 p-2">
          <div class="w-full mb-4">
            <h3 class="text-center text-xl font-bold text-gray-900">
              Status
            </h3>
      </div>
      <div class="flex flex-col space-y-2 items-center w-full mb-2">
        <div class="flex items-center w-full">
          <div class="text-gray-800 text-lg font-medium">Bot status:</div>
          <div class="ml-auto w-20 px-3 py-1 text-white text-center text-lg font-medium bg-green-500 rounded-md status-width shadow-md">Active</div>
        </div>
        <div class="flex items-center w-full">
          <div class="text-gray-800 text-lg font-medium">Server status:</div>
          <div class="ml-auto w-20 px-3 py-1 text-white text-center text-lg font-medium bg-yellow-500 rounded-md status-width shadow-md">Online</div>
        </div>
        <div class="flex items-center w-full">
          <div class="text-gray-800 text-lg font-medium">HC Status:</div>
          <div class="ml-auto w-20 px-3 py-1 text-white text-center text-lg font-medium bg-red-500 rounded-md status-width shadow-md">Error</div>
        </div>
      </div>
    </div>
        <!-- Right Side -->
        <div class="flex flex-col w-full sm:w-1/3 items-center bg-gray-200 rounded-lg sm:ml-2 shadow-md p-2">
          <div class="w-full mb-4">
            <h3 class="text-center text-xl font-bold text-gray-900">
              Place order
            </h3>
      </div>
      <!-- BUY and SELL Buttons -->
      <div class="flex justify-center space-x-2 mb-2">
      <button @click="openPopup('buy')" class="bg-blue-500 hover:bg-blue-600 text-white font-bold uppercase text-lg px-5 py-2 rounded  hover:shadow-md outline-none focus:outline-none transition duration-150 shadow-md" type="button">
        BUY
      </button>
      <button @click="openPopup('sell')" class="bg-blue-500 hover:bg-blue-600 text-white font-bold uppercase text-lg px-5 py-2 rounded  hover:shadow-md outline-none focus:outline-none transition duration-150 shadow-md" type="button">
        SELL
      </button>
    </div>

      <!-- Lot Size -->
      <div class="flex justify-center items-center mb-4 border-2 border-gray-400 rounded-md p-1 shadow-lg">
        <label for="lot" class="text-gray-700 text-md font-semibold mr-2">Lot size:</label>
        <select id="lot" v-model="selectedLotSize" name="lot" class="bg-gray-200 border border-gray-200 text-gray-700 font-semibold py-2 px-4 rounded leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-200">
          <!-- Options -->
          <option class="font-semibold" value="0.01">0.01</option>
          <option class="font-semibold" value="0.02">0.02</option>
          <option class="font-semibold" value="0.03">0.03</option>
          <option class="font-semibold" value="0.04">0.04</option>
          <option class="font-semibold" value="0.05">0.05</option>
          <option class="font-semibold" value="0.06">0.06</option>
          <option class="font-semibold" value="0.07">0.07</option>
          <option class="font-semibold" value="0.08">0.08</option>
          <option class="font-semibold" value="0.09">0.09</option>
          <option class="font-semibold" value="0.1">0.1</option>
          <option class="font-semibold" value="0.2">0.2</option>
          <option class="font-semibold" value="0.3">0.3</option>
          <option class="font-semibold" value="0.4">0.4</option>
          <option class="font-semibold" value="0.5">0.5</option>
        </select>
      </div>
    </div>

  </div>
</div>





  <!--********************************************************************************************************************************-->
  <!-- TradingView Widget-->
  <div class="h-96 max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2"> 
    <BtcChart/>
  </div>
 

  <!--********************************************************************************************************************************-->
<!-- Open positions -->
<div class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
  <div class="flex justify-between items-center">
    <h2 class="text-left text-2xl font-extrabold text-gray-900 mb-4">
      Open positions
    </h2>
    <span class="bg-gray-200 py-1 px-2 rounded font-semibold">
      Unr. Profit: 
      <span :class="{'text-green-500': totalUnrealisedProfit >= 0, 'text-red-500': totalUnrealisedProfit < 0}">
        {{ totalUnrealisedProfit }}
      </span>
    </span>
  </div>
  <hr>
  <div class="overflow-x-auto" style="max-height: 70vh; overflow-y: auto;">
    <table class="table-auto w-full mt-2 mb-2">
      <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th class="py-3 px-2 text-left">Symbol</th>
          <th class="py-3 px-2 text-left hidden lg:table-cell">ID</th>
          <th class="py-3 px-2 text-left hidden md:table-cell">Time</th>
          <th class="py-3 px-2 text-left">Type</th> 
          <th class="py-3 px-2 text-left">Vol</th>
          <th class="py-3 px-2 text-left hidden md:table-cell">Price</th>
          <th class="py-3 px-2 text-left">Profit</th>
          <th class="py-3 px-2 text-center">Action</th>
        </tr>
      </thead>
      <tbody class="text-gray-600 text-sm font-light">
        <tr v-for="(item) in trades" :key="item.id" class="border-b border-gray-200 hover:bg-gray-100">
          <td class="py-3 px-2 text-left font-medium whitespace-nowrap">{{ item.symbol }}</td>
          <td class="py-3 px-2 text-left font-medium hidden lg:table-cell">{{ item.id }}</td>
          <td class="py-3 px-2 text-left font-medium hidden md:table-cell">{{ item.time }}</td>
          <td class="py-3 px-2 text-left font-medium">{{ item.type }}</td>
          <td class="py-3 px-2 text-left font-medium">{{ item.volume }}</td>
          <td class="py-3 px-2 text-left font-medium hidden md:table-cell">{{ item.price }}</td>
          <td :class="{'text-green-500': item.profit >= 0, 'text-red-500': item.profit < 0}" class="py-3 px-2 text-left font-medium">{{ item.profit }}</td>
          <td class="py-3 px-2 text-center">
            <button @click="openClosePopup(item.id, item.volume)" class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" >
              <img src="../assets/x.svg" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

    
<!--********************************************************************************************************************************-->
<!-- Bot Settings Box -->
<div class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
  <h2 class="text-left text-2xl font-extrabold text-gray-900 mb-4">
    Bot settings
  </h2>
  <hr>

  <!-- Main Container Split into Vertical Sections -->
  <div class="flex flex-col sm:flex-row mt-2">



    <!-- User defined settings-->
    <div class="flex flex-col w-full sm:w-1/3 items-center bg-gray-200 rounded-lg px-4 shadow-md mb-3 sm:mb-0 p-2">
      <div class="w-full mb-4">
        <h3 class="text-center text-xl font-bold text-gray-900">
          User defined settings
        </h3>
      </div>
      <div class="flex flex-col space-y-2 items-center w-full">
  <div class="flex items-center justify-between w-full">
    <div class="text-gray-800 text-lg font-medium">Ignore BUY signals:</div>
    <label class="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" value="" class="sr-only peer">
      <div class="w-11 h-6 bg-gray-700 peer-focus:outline-none  dark:peer-focus:ring-yellow-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400 dark:peer-checked:bg-yellow-500"></div>
    </label>
  </div>
  <div class="flex items-center justify-between w-full">
    <div class="text-gray-800 text-lg font-medium">Ignore BUY signals:</div>
    <label class="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" value="" class="sr-only peer">
      <div class="w-11 h-6 bg-gray-700 peer-focus:outline-none dark:peer-focus:ring-yellow-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400 dark:peer-checked:bg-yellow-500"></div>
    </label>
  </div>
  <div class="flex items-center justify-between w-full">
    <div class="text-gray-800 text-lg font-medium">Empty setting:</div>
    <label class="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" value="" class="sr-only peer">
      <div class="w-11 h-6 bg-gray-700 peer-focus:outline-none  dark:peer-focus:ring-yellow-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400 dark:peer-checked:bg-yellow-500"></div>
    </label>
  </div>
</div>

    </div>

    <!-- Empty space 2 -->
    <div class="flex flex-col w-full sm:w-1/3 items-center bg-gray-200 rounded-lg sm:ml-2  shadow-md ">
      <div class="w-full mb-4 ">
        <h3 class="text-center text-xl font-bold text-gray-900 mt-2">
          Predefined settings
        </h3>
        
      </div>
    </div>

<!-- Other settings -->
<div class="flex flex-col w-full sm:w-1/3 items-center bg-gray-200 rounded-lg sm:ml-2 shadow-md p-4 mt-3 sm:mt-0">
  <div class="w-full mb-4">
    <h3 class="text-center text-xl font-bold text-gray-900">
      Other settings
    </h3>
  </div>

  <!-- Bot risk management -->
  <div class="flex items-center justify-between w-full mb-4">
    <div class="text-gray-800 text-lg font-medium">Bot risk management:</div>
    <select class="bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded focus:outline-none focus:ring focus:border-blue-300">
      <option value="low">Low</option>
      <option value="regular">Regular</option>
      <option value="high">High</option>
    </select>
  </div>

  <!-- Stop loss setting -->
  <div class="flex items-center justify-between w-full mb-4">
    <div class="text-gray-800 text-lg font-medium">Stop loss(%):</div>
    <select v-model="selectedStopLoss" class="bg-white border border-gray-300 text-gray-700 py-2 px-4 rounded focus:outline-none focus:ring focus:border-blue-300">
      <option v-for="value in stopLossOptions" :key="value" :value="value">{{ value }}</option>
    </select>
  </div>

  <!-- Selection of trading pairs -->
  <div class="w-full">
    <button @click="openTradingPairsPopup" class="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
      Select Trading Pairs
    </button>
  </div>

  <!-- Trading Pairs Popup Component -->
  <TradingPairsPopup 
    :showPopup="isTradingPairsPopupVisible"
    @close-popup="isTradingPairsPopupVisible = false"
  />
</div>




  </div>
</div>


<!--********************************************************************************************************************************-->
<!-- Log window -->
    <div v-if="loggedUser === 'master' || loggedUser === 'admin'" class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
  <div class="flex justify-between items-center mb-4">
    <h2 class="text-2xl font-extrabold text-gray-900">Log</h2>
    <div>
      <!-- Responsive Filter Dropdown for small screens -->
      <div class="sm:hidden">
        <select @change="selectedSource = $event.target.value" class="bg-gray-200 text-black p-2 rounded font-semibold">
          <option class="font-semibold" v-for="src in sources" :key="src" :value="src">{{ src }}</option>
        </select>
        <select @change="selectedSeverity = $event.target.value" class="bg-gray-200 text-black p-2 ml-2 rounded font-semibold">
          <option class="font-semibold" v-for="sev in severities" :key="sev" :value="sev">{{ sev }}</option>
        </select>
      </div>
      <!-- Inline Filter Buttons for larger screens -->
      <div class="hidden sm:inline-flex">
        <div class="inline-flex">
          <button v-for="src in sources" :key="src" 
            :class="{'bg-blue-500 text-white': selectedSource === src, 'bg-gray-200 text-black': selectedSource !== src}"
            @click="selectedSource = src" class="font-semibold py-2 px-4  first:rounded-none hover:bg-blue-600 transition-colors">
            {{ src }}
          </button>
        </div>
        <div class="inline-flex ml-2">
          <button v-for="sev in severities" :key="sev"
            :class="{'bg-blue-500 text-white': selectedSeverity === sev, 'bg-gray-200 text-black': selectedSeverity !== sev}"
            @click="selectedSeverity = sev" class="font-semibold py-2 px-4 first:rounded-none  hover:bg-blue-600 transition-colors">
            {{ sev }}
          </button>
        </div>
      </div>
    </div>
  </div>
      <div class="overflow-x-auto" style="max-height: 70vh; overflow-y: auto;">
        <table class="table-auto w-full mt-2 mb-2">
          <thead>
            <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th class="py-3 px-2 text-left">Description</th>
              <th class="py-3 px-2 text-left">Source</th>
              <th class="py-3 px-2 text-left">Date</th>
              <th class="py-3 px-2 text-left">Severity</th>
            </tr>
          </thead>
          <tbody class="text-gray-600 text-sm font-light">
  <template v-for="(alert, index) in paginatedAlerts" :key="index">
    <tr @click="toggleRow(index)"
        :class="{'bg-gray-100': expandedIndex === index, 'cursor-pointer': true}"
        class="border-b border-gray-200 hover:bg-gray-200">
      <td class="py-3 px-2 text-left font-medium">{{ alert.description }}</td>
      <td class="py-3 px-2 text-left font-medium">{{ alert.source }}</td>
      <td class="py-3 px-2 text-left font-medium">{{ alert.date }}</td>
      <td :class="{'text-yellow-500': alert.severity == 'WARN', 'text-red-500': alert.severity == 'ERR'}"
          class="py-3 px-2 text-left font-medium">{{ alert.severity }}</td>
    </tr>
  </template>
</tbody>

        </table>
      </div>
      <!-- Pagination Controls -->
      <div class="flex justify-between items-center mt-4">
        <button @click="currentPage = Math.max(currentPage - 1, 1)"
                :disabled="currentPage === 1"
                :class="{'invisible': currentPage === 1}"
                class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded shadow-md">
          <img src="../assets/arrow-left-solid.svg" alt="Previous" class="h-4 w-4"/>
        </button>
        <span class="mx-auto font-semibold text-gray-600">Page {{ currentPage }} of {{ pageCount }}</span>
        <button @click="currentPage = Math.min(currentPage + 1, pageCount)"
                :disabled="currentPage === pageCount"
                :class="{'invisible': currentPage === pageCount}"
                class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded shadow-md">
          <img src="../assets/arrow-right-solid.svg" alt="Next" class="h-4 w-4"/>
        </button>
      </div>
    </div>


    <!--Received signals-->
    <div v-if="loggedUser === 'master'" class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
  <h2 class="text-left text-2xl font-extrabold text-gray-900 mb-4">
    Received Signals
  </h2>
  <hr>
  <div class="overflow-x-auto">
    <table class="table-auto w-full mt-2 mb-2">
      <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th class="py-3 px-2 text-left">Symbol</th>
          <th class="py-3 px-2 text-left">Type</th>
          <th class="py-3 px-2 text-left">Time</th>
          <th class="py-3 px-2 text-left">Interval</th>
        </tr>
      </thead>
      <tbody class="text-gray-600 text-sm font-light">
        <tr v-for="signal in signals" :key="signal.time" class="border-b border-gray-200 hover:bg-gray-100">
          <td class="py-3 px-2 text-left font-medium">{{ signal.symbol }}</td>
          <td class="py-3 px-2 text-left font-medium">{{ signal.type }}</td>
          <td class="py-3 px-2 text-left font-medium">{{ signal.time }}</td>
          <td class="py-3 px-2 text-left font-medium">{{ signal.interval }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<!--Filtered Signals-->
<div v-if="loggedUser === 'master'" class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
  <h2 class="text-left text-2xl font-extrabold text-gray-900 mb-4">
    Filtered Signals
  </h2>
  <hr>
  <div class="overflow-x-auto">
    <table class="table-auto w-full mt-2 mb-2">
      <thead>
        <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
          <th class="py-3 px-2 text-left">Symbol</th>
          <th class="py-3 px-2 text-left">Type</th>
          <th class="py-3 px-2 text-left">Time</th>
          <th class="py-3 px-2 text-left">Interval</th>
        </tr>
      </thead>
      <tbody class="text-gray-600 text-sm font-light">
        <tr v-for="signal in filtered_signals" :key="signal.time" class="border-b border-gray-200 hover:bg-gray-100">
          <td class="py-3 px-2 text-left font-medium">{{ signal.symbol }}</td>
          <td class="py-3 px-2 text-left font-medium">{{ signal.type }}</td>
          <td class="py-3 px-2 text-left font-medium">{{ signal.time }}</td>
          <td class="py-3 px-2 text-left font-medium">{{ signal.interval }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
  

  <!--********************************************************************************************************************************-->
      <!-- Order history -->


    <div class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
  <div class="flex justify-between items-center">
    <h2 class="text-left text-2xl font-extrabold text-gray-900 mb-4">
      Order history
    </h2>
    <span class="bg-gray-200 py-1 px-2 rounded font-semibold">
      Net. Profit: 
      <span :class="{'text-green-500': totalProfit >= 0, 'text-red-500': totalProfit < 0}">
        {{ totalProfit }}
      </span>
    </span>
  </div>
    <hr>
        <div class="overflow-x-auto" style="max-height: 70vh; overflow-y: auto;">
          <table class="table-auto w-full mt-2 mb-2">
            <thead>
              <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th class="py-3 px-2 text-left">Symbol</th>
                <th class="py-3 px-2 text-left hidden lg:table-cell">ID</th>
                <th class="py-3 px-2 text-left hidden md:table-cell">Time</th>
                <th class="py-3 px-2 text-left">Vol</th>
                <th class="py-3 px-2 text-left">Buy</th> 
                <th class="py-3 px-2 text-left hidden md:table-cell">Sell</th>
                <th class="py-3 px-2 text-left">Profit</th>
              </tr>
            </thead>
            <tbody class="text-gray-600 text-sm font-light">
            <tr v-for="(item) in trades_history" :key="item.id" class="border-b border-gray-200 hover:bg-gray-100">
              <td class="py-3 px-2 text-left font-medium whitespace-nowrap">{{ item.symbol }}</td>
              <td class="py-3 px-2 text-left font-medium hidden lg:table-cell">{{ item.id }}</td>
              <td class="py-3 px-2 text-left font-medium hidden md:table-cell">{{ item.time }}</td>
              <td class="py-3 px-2 text-left font-medium">{{ item.volume }}</td>
              <td class="py-3 px-2 text-left font-medium">{{ item.buy }}</td>
              <td class="py-3 px-2 text-left font-medium hidden md:table-cell">{{ item.sell }}</td>
              <td :class="{'text-green-500': item.profit >= 0, 'text-red-500': item.profit < 0}" class="py-3 px-2 text-left font-medium">{{ item.profit }}</td>
            </tr>
          </tbody>
          </table>
        </div>
      </div>
  

      <!--********************************************************************************************************************************-->
      <!-- ROI Chart -->
      <div class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
        <h2 class="text-left text-2xl font-extrabold text-gray-900 mb-4">
          Equity hisory chart
        </h2>
        <hr>
        <div class="overflow-x-auto" style="max-height: 70vh; overflow-y: auto;">
         
          <RoiChart/>
        
        </div>
      </div>

      <!--Catch error-->
    <!--********************************************************************************************************************************-->
    <div class="max-w-4xl w-full p-4 bg-white rounded-lg shadow-md mb-2">
  <h2 class="text-left text-2xl font-extrabold text-gray-900 mb-4">Console Errors</h2>
  <hr>
  <div v-if="fetchError" class="error-message">{{ fetchError }}</div>
  <table v-if="trades.length">
    <!-- Your table rendering logic here -->
  </table>
  
  <div class="console-messages-wrapper">
    <table class="table-fixed w-full mt-2 mb-2">
      <thead class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
        <tr>
          <th class="py-3 px-2 text-left">Message</th>
          <th class="py-3 px-2 text-left">Type</th>
          <th class="py-3 px-2 text-left">Timestamp</th>
        </tr>
      </thead>
    </table>
    <div class="console-messages fixed-height">
      <table class="table-fixed w-full">
        <tbody class="text-gray-600 text-sm font-light">
          <tr v-for="(msg, index) in paddedConsoleMessages" :key="index" class="border-b border-gray-200 hover:bg-gray-100">
            <td class="py-3 px-2 text-left font-medium">{{ msg.message }}</td>
            <td class="py-3 px-2 text-left font-medium">{{ msg.type }}</td>
            <td class="py-3 px-2 text-left font-medium">{{ msg.timestamp }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>



<!--End of catch error-->


  </div>
  <div v-else>
    <p>You are not authorized to view this page. Please <router-link to="/">login</router-link>.</p>
  </div>
</div>
</template>

<script>

import RoiChart from './EquityChart.vue';
import BtcChart from './BtcChart.vue';
import ConfirmTrade from './ConfirmTrade.vue';
import ConfirmClose from './ConfirmClose.vue';
import PreferencesPage from './PreferencesPage.vue';
import SettingsPage from './SettingsPage.vue';
import SupportPage from './SupportPage.vue';
import AboutPage from './AboutPage.vue';
import ConfirmBotKillSwitch from './ConfirmBotKillSwitch.vue';
import TradingPairsPopup from './TradingPairs.vue';

export default {



  components: {
    RoiChart,
    BtcChart,
    ConfirmTrade,
    ConfirmClose,
    AboutPage,
    PreferencesPage,
    SettingsPage,
    SupportPage,
    ConfirmBotKillSwitch,
    TradingPairsPopup
  },  

  
  data() {
    return {
      //popup return data
      showPopup: false,
      tradeType: '',
      tradeSymbol: 'BTCUSD', // Example symbol
      tradeVolume: 0.1 ,// Example volume
      //----------------
      // Time data
      currentTime: '',
      trades_err: [],//debug log
      fetchError: null,//debug log
      consoleMessages: [],//debug log
      isAuthenticated: false,

    showDropdown: false,
    showClosePopup: false,
    closingPositionId: null,
    closingPositionVolume: null,

    isDarkMode: false,
    showKillSwitchPopup: false,
    isTradingPairsPopupVisible: false,
    
    selectedStopLoss: 50,
      stopLossOptions: Array.from({ length: 21 }, (v, i) => i * 5),
      
      //log:[],

      trades:  [
          /*
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', type: 'BUY', volume: 0.01, price: 62055.94, profit: -0.45 },
          { id: 68890673, symbol: 'BTCUSD', time: '2024.04.16 13:33:36', type: 'SELL', volume: 0.01, price: 62055.94, profit: -0.2 },
          { id: 68890673, symbol: 'BTCUSD', time: '2024.04.16 13:33:36', type: 'SELL', volume: 0.01, price: 62055.94, profit: -0.2 },
          { id: 68890673, symbol: 'BTCUSD', time: '2024.04.16 13:33:36', type: 'SELL', volume: 0.01, price: 62055.94, profit: -0.2 },
          { id: 68890673, symbol: 'BTCUSD', time: '2024.04.16 13:33:36', type: 'SELL', volume: 0.01, price: 62055.94, profit: -0.2 },
          { id: 68890673, symbol: 'BTCUSD', time: '2024.04.16 13:33:36', type: 'SELL', volume: 0.01, price: 62055.94, profit: -0.2 },
          { id: 68890673, symbol: 'BTCUSD', time: '2024.04.16 13:33:36', type: 'SELL', volume: 0.01, price: 62055.94, profit: -0.2 },
          { id: 68890673, symbol: 'BTCUSD', time: '2024.04.16 13:33:36', type: 'SELL', volume: 0.01, price: 62055.94, profit: -0.2 },
          */
        ],
      alerts: [
        { description: 'PTOS BUY at 63110', source: 'MT5', date: '22:40:50 11.05.2024', severity: 'MSG', fullDescription: '2024.05.12 23:33:33.366	bot_v2 (BTCUSD,M30) new signal [PTOS_BUY] at [2024.05.13 00:33:42]' },
        { description: 'PTOS SELL at 68333', source: 'Server', date: '01:05:33 15.05.2024', severity: 'WARN', fullDescription: '2024.05.13 02:15:31.906	bot_v2 (BTCUSD,M30)	new signal [PTOS_SELL] at [2024.05.13 03:15:42]' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'ERR', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'PTOS BUY at 63110', source: 'MT5', date: '22:40:50 11.05.2024', severity: 'MSG', fullDescription: '2024.05.12 23:33:33.366	bot_v2 (BTCUSD,M30) new signal [PTOS_BUY] at [2024.05.13 00:33:42]' },
        { description: 'PTOS SELL at 68333', source: 'MT5', date: '01:05:33 15.05.2024', severity: 'WARN', fullDescription: '2024.05.13 02:15:31.906	bot_v2 (BTCUSD,M30)	new signal [PTOS_SELL] at [2024.05.13 03:15:42]' },
        { description: 'Heron copier failed to copy', source: 'Server', date: '13:01:10 20.05.2024', severity: 'ERR', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'PTOS BUY at 63110', source: 'MT5', date: '22:40:50 11.05.2024', severity: 'MSG', fullDescription: '2024.05.12 23:33:33.366	bot_v2 (BTCUSD,M30) new signal [PTOS_BUY] at [2024.05.13 00:33:42]' },
        { description: 'PTOS SELL at 68333', source: 'Server', date: '01:05:33 15.05.2024', severity: 'WARN', fullDescription: '2024.05.13 02:15:31.906	bot_v2 (BTCUSD,M30)	new signal [PTOS_SELL] at [2024.05.13 03:15:42]' },
        { description: 'Heron copier failed to copy', source: 'Server', date: '13:01:10 20.05.2024', severity: 'MSG', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'MSG', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'Server', date: '13:01:10 20.05.2024', severity: 'WARN', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'Server', date: '13:01:10 20.05.2024', severity: 'ERR', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'WARN', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'Server', date: '13:01:10 20.05.2024', severity: 'MSG', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'ERR', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'WARN', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'MSG', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'WARN', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'Server', date: '13:01:10 20.05.2024', severity: 'ERR', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'ERR', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'WARN', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'Server', date: '13:01:10 20.05.2024', severity: 'ERR', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
        { description: 'Heron copier failed to copy', source: 'MT5', date: '13:01:10 20.05.2024', severity: 'ERR', fullDescription: '2024.05.13 11:09:58.453	Heron Copier Master (BTCUSD,M5)	Heron Copier v1.4.6: Close|||||||70518597' },
      ],

      signals:  [
          {symbol: 'BTCUSD', type: 'PTOS_BUY',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_SELL',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_BUY',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_BUY',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_SELL',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_BUY',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_SELL',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_BUY',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_SELL',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_SELL',  time: '2024.04.16 13:33:34', interval: 5},
      ],
      
      filtered_signals:  [
          {symbol: 'BTCUSD', type: 'PTOS_BUY',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_SELL',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_BUY',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_SELL',  time: '2024.04.16 13:33:34', interval: 5},
          {symbol: 'BTCUSD', type: 'PTOS_SELL',  time: '2024.04.16 13:33:34', interval: 5},
      ],
      trades_history:  [
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 62056.44, profit: 1.55 },
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 63055.94, profit: 1000 },
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 61055.94, profit: -1000 },
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 62056.44, profit: 1.55 },
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 63055.94, profit: 1000 },
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 61055.94, profit: -1000 },  
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 62056.44, profit: 1.55 },
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 63055.94, profit: 1000 },
          { id: 68890672, symbol: 'BTCUSD', time: '2024.04.16 13:33:34', volume: 0.01, buy: 62055.94, sell: 61055.94, profit: -1000 },            
        ],
      expandedIndex: -1,
      currentPage: 1,
      pageSize: 10,
      selectedSource: 'All',
      selectedSeverity: 'All',
      sources: ['All', 'MT5', 'Server'],
      severities: ['All', 'MSG', 'WARN', 'ERR'],
    };
  },

  beforeMount() {
  const token = localStorage.getItem('token');
  if (!token) {
    this.$router.push('/');
  } else {
    this.isAuthenticated = true;
  }
},



  computed: {

    themeClass() {
      const scheme = localStorage.getItem('color-scheme') || 'blue-yellow';
      return {
        'blue-yellow': scheme === 'blue-yellow',
        'red-green': scheme === 'red-green',
        'purple-orange': scheme === 'purple-orange',
      };
    },
    paginatedAlerts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredAlerts.slice(start, end);
    },
    filteredAlerts() {
      return this.alerts.filter(alert => {
        const sourceMatch = this.selectedSource === 'All' || alert.source === this.selectedSource;
        const severityMatch = this.selectedSeverity === 'All' || alert.severity === this.selectedSeverity;
        return sourceMatch && severityMatch;
      });
    },
    pageCount() {
      return Math.ceil(this.filteredAlerts.length / this.pageSize);
    },

    loggedUser() {
    return localStorage.getItem('loggedUser') || '';
    },
      currentDate() {
      return this.currentTime.split(', ')[0];
    },
    currentTimeOnly() {
      return this.currentTime.split(', ')[1];
    },
    totalUnrealisedProfit() {
    return this.trades.reduce((total, trade) => total + trade.profit, 0).toFixed(2);
    
  },
  totalProfit() {
  return this.trades_history.reduce((total, trade) => total + trade.profit, 0).toFixed(2);
},

paddedConsoleMessages() {
    const paddedMessages = [...this.consoleMessages];
    while (paddedMessages.length < 10) {
      paddedMessages.push({ message: '', type: '', timestamp: '' });
    }
    return paddedMessages;
  }

},

  methods: {

    openTradingPairsPopup() {
    this.isTradingPairsPopupVisible = true;
  },


    toggleRow(index) {
      this.expandedIndex = this.expandedIndex === index ? -1 : index;
    },
    deleteRow(index) {
      let data = {
        "key": this.trades[index]
      };
      console.log(this.trades[index]);
      this.trades.splice(index, 1);
      fetch('https://server.vue.kopower.si/close_position', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    },
    
    updateTable() {
      fetch("https://server.vue.kopower.si/http_get_positions")
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.trades = data;
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    },

    /*
    //Update log table
    //**************************************************** 
    updateLog() {
  let requestData = {
    "log_request": [
      { "numberOfReq": 10 }
    ]
  };

  fetch('https://3.127.239.151/log_request', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestData)
  })
  .then(response => response.json())
  .then(data => {
    console.log('Success:', data);
    // Store response data in a variable named log_data
    let log_data = data;
    // Now you can use the log_data variable as needed
  })
  .catch((error) => {
    console.error('Error:', error);
  });
},
*/
//*********************************************************** 



//Popup methods----------
  openPopup(type) {
      this.tradeType = type;
      this.tradeVolume = parseFloat(this.selectedLotSize);
      this.showPopup = true;
    },
    handleTradeConfirm(tradeDetails) {
      this.showPopup = false;
      // Handle the trade confirmation logic here
      this.$toast.show(`Successfully placed ${tradeDetails.type} order for ${tradeDetails.volume} lots of ${tradeDetails.symbol}.`, {
        type: 'success'
      });
    },
    handlePopupClose() {
      this.showPopup = false;
    },
//End of Popup methods----------

//Close position method
openClosePopup(id, volume) {
        this.closingPositionId = id;
        this.closingPositionVolume =volume;
        this.showClosePopup = true;
    },
    handlePositionClose(id) {
    this.showClosePopup = false;
    // Logic to close the position
    this.$toast.show(`Position ${id} closed`);
    console.log(`Position ${id} closed`);

    // Find the index of the trade with the given id
    const index = this.trades.findIndex(trade => trade.id === id);

    // If a matching trade is found, delete the row
    if (index !== -1) {
        this.deleteRow(index);
    }
},

    handlePopupCloseTrade() {
        this.showClosePopup = false;
    },





    //Update time
    updateCurrentTime() {
      const now = new Date();
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
      const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
      const date = now.toLocaleDateString('en-GB', dateOptions);
      const time = now.toLocaleTimeString('en-GB', timeOptions);
      this.currentTime = `${date}, ${time}`;
    },

    //Fetch error
    overrideConsole() {
    const originalConsoleLog = console.log;
    const originalConsoleError = console.error;
    const vm = this;

    console.log = function (...args) {
      vm.consoleMessages.push({ 
        type: 'log', 
        message: args.join(' '),
        timestamp: new Date().toLocaleString()
      });
      originalConsoleLog.apply(console, args);
    };

    console.error = function (...args) {
      vm.consoleMessages.push({ 
        type: 'error', 
        message: args.join(' '),
        timestamp: new Date().toLocaleString()
      });
      originalConsoleError.apply(console, args);
    };
  },
    updateTable_err() {
      fetch("https://server.vue.kopower.si/http_get_positions")
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          this.trades = data;
          this.fetchError = null; // Clear any previous errors
        })
        .catch(error => {
          this.fetchError = 'There was a problem with the fetch operation: ' + error.message;
          console.error(this.fetchError);
        });
    },
    //End of fetch error
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    showPreferences() {
      this.isPreferencesVisible = true;
      this.showDropdown = false;
    },
    showSupport() {
      this.isSupportVisible = true;
      this.showDropdown = false;
    },
    showSettings() {
      this.isSettingsVisible = true;
      this.showDropdown = false;
    },
    showAbout() {
      this.isAboutVisible = true;
      this.showDropdown = false;
    },
    logOut() {
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('password');
    this.$router.push('/'); // Redirect to login page or home page
    },


    toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    if (this.isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  },

  sendKillSignal() {
      this.showKillSwitchPopup = true;
    },
    handleKillSwitchConfirm() {
      
      console.log('Bot stopped');
    },
    handleKillSwitchPopupClose() {
      this.showKillSwitchPopup = false;
    },
  
  },
  created() {
    this.pageCount = Math.ceil(this.alerts.length / this.pageSize);
    this.updateCurrentTime(); //Date and time
    this.overrideConsole();//Za debug window
    this.updateTable_err(); //Za debug window
    const savedScheme = localStorage.getItem('color-scheme') || 'blue-yellow';
    document.documentElement.setAttribute('data-theme', savedScheme);
  },
  watch: {
    alerts(newAlerts) {
      this.pageCount = Math.ceil(newAlerts.length / this.pageSize);
    }
  },
  mounted() {

    this.updateTable();
    setInterval(() => this.updateTable(), 1000);  // Refresh data every 5 seconds

  //  this.updateLog();
    //setInterval(() => this.updateLog(), 5000);  // Refresh data every 5 seconds

    setInterval(() => this.updateCurrentTime(), 1000);  // Update time every second

    const savedScheme = localStorage.getItem('color-scheme') || 'blue-yellow';
    document.documentElement.setAttribute('data-theme', savedScheme);
  


  }
};
</script>

<style scoped>
.dropdown-enter-active, .dropdown-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.dropdown-enter, .dropdown-leave-to /* .dropdown-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-10px);
}


</style>