<template>
  <div>
    <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
      <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full relative">
        <button @click="close" class="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 class="text-2xl font-semibold mb-4">Support</h2>
        <p class="text-gray-700 mb-4">
          Please describe your problem and provide your email address. We will respond within 24 hours.
        </p>
        <form @submit.prevent="submitSupportRequest">
          <div class="mb-4">
            <label for="email" class="block text-gray-700 mb-2">Email:</label>
            <input type="email" id="email" v-model="email" required class="w-full p-2 border border-gray-300 rounded-lg" />
          </div>
          <div class="mb-4">
            <label for="description" class="block text-gray-700 mb-2">Description:</label>
            <textarea id="description" v-model="description" required class="w-full p-2 border border-gray-300 rounded-lg" rows="4"></textarea>
          </div>
          <button type="submit" class="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700">Submit</button>
        </form>
      </div>
    </div>
    <ContactSupportSuccess :isVisible="showSuccessPopup" @close="closeSuccessPopup" />
  </div>
</template>

<script>
import ContactSupportSuccess from './ContactSupportSuccess.vue';

export default {
  components: {
    ContactSupportSuccess
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      email: '',
      description: '',
      showSuccessPopup: false
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    closeSuccessPopup() {
      this.showSuccessPopup = false;
      this.$emit('close');
    },
    submitSupportRequest() {
      const formData = {
        email: this.email,
        description: this.description
      };
      fetch('https://server.vue.kopower.si//support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Support request failed');
        }
        return response.json();
      })
      .then(data => {
        console.log('Response data:', data);
        this.showSuccessPopup = true; // Show success popup
      })
      .catch(err => {
        console.error(err);
      });
      // Clear the form
      this.email = '';
      this.description = '';
      // Close the modal
      this.close();
    }
  }
}
</script>

<style scoped>
</style>
