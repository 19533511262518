<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100 px-4 sm:px-6 lg:px-8">
    <div class="max-w-sm w-full space-y-8 p-6 bg-white rounded-lg shadow-md">
      <div>
        <h2 class="text-center text-3xl font-extrabold text-gray-900">
          Trading bot portal
        </h2>
      </div>
      <form class="space-y-3" @submit.prevent="login">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="username" class="sr-only">Username</label>
            <input id="username" v-model="username" name="username" type="text" autocomplete="username" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" placeholder="Username">
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input id="password" v-model="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" placeholder="Password">
          </div>
        </div>

        <div class="flex justify-between items-center">
          <button type="button" @click="showForgotPassword = true" class="text-green-600 hover:underline text-md">Forgot password?</button>
          <div class="flex items-center">
            <label for="remember_me" class="mr-2 text-gray-900">Remember me:</label>
            <input id="remember_me" v-model="rememberMe" type="checkbox" class="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded">
          </div>

        </div>

        <div>
          <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Sign in
          </button>
          <div class="flex justify-center items-center mt-2">
            <span>Don't have an account yet?</span>
            <button type="button" @click="showSignUp = true" class="ml-2 text-green-600 hover:underline">Sign up</button>
          </div>
        </div>
      </form>
      <wrong-credentials :isVisible="showWrongCredentials" @close="showWrongCredentials = false"></wrong-credentials>
      <forgot-password :isVisible="showForgotPassword" @close="showForgotPassword = false" @submit-email="handleForgotPasswordEmail"></forgot-password>
      <sign-up :isVisible="showSignUp" @close="showSignUp = false" @submit-form="handleSignUpForm"></sign-up>
    </div>
  </div>
</template>

<script>
import WrongCredentials from './WrongCredentials.vue';
import ForgotPassword from './ForgotPassword.vue';
import SignUp from './SignUp.vue';

export default {
  components: {
    WrongCredentials,
    ForgotPassword,
    SignUp
  },
  data() {
    return {
      username: '',
      password: '',
      rememberMe: false,
      showWrongCredentials: false,
      showForgotPassword: false,
      showSignUp: false,
    };
  },
  methods: {
    login() {
      fetch('https://server.vue.kopower.si/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password
        })
      })
      .then(response => {
        if (!response.ok) {
          this.showWrongCredentials = true;
          throw new Error('Invalid credentials');
        }
        return response.json();
      })
      .then(data => {
          const token = data.access_token;
          localStorage.setItem('token', token);
          localStorage.setItem('loggedUser', this.username); // Always save username to local storage
          if (this.rememberMe) { // Check if rememberMe is true
            localStorage.setItem('password', this.password); // Save password to local storage
          } else {
            localStorage.removeItem('password'); // Ensure password is not stored
          }
          this.$router.push('/management-ui');
        })
        .catch(err => {
          console.error(err);
        });
    },
    handleForgotPasswordEmail(email) {
      // Logic to handle forgot password email submission
      console.log(`Forgot password email submitted: ${email}`);
      this.showForgotPassword = false;
    },
    handleSignUpForm(formData) {
      // Logic to handle sign up form submission
      console.log(`Sign up form submitted: ${JSON.stringify(formData)}`);
      this.showSignUp = false;
    }
  },
  created() {
    const storedUser = localStorage.getItem('loggedUser');
    const storedPassword = localStorage.getItem('password');
    if (storedUser) {
      this.username = storedUser;
    }
    if (storedPassword) {
      this.password = storedPassword;
      this.rememberMe = true; // Set rememberMe to true if password is stored
    }
  }
}
</script>

<style scoped>
/* Add your styles here */
</style>
