<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full relative">
      <button @click="close" class="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-800">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 class="text-2xl font-semibold mb-4">Preferences</h2>

      <!-- Color Scheme Selection -->
      <div class="mb-4">
        <label class="block text-gray-700 mb-2">Color Scheme</label>
        <div class="flex space-x-2">
          <button
            @click="setColorScheme('white-black')"
            :class="{'border-4 border-blue-500': selectedScheme === 'white-black'}"
            class="w-10 h-10 bg-white border-4 rounded-full"
          ></button>
          <button
            @click="setColorScheme('dark-gray')"
            :class="{'border-4 border-blue-500': selectedScheme === 'dark-gray'}"
            class="w-10 h-10 bg-gray-700 border-4 rounded-full"
          ></button>
          <button
            @click="setColorScheme('blue-white')"
            :class="{'border-4 border-blue-500': selectedScheme === 'blue-white'}"
            class="w-10 h-10 bg-blue-200 border-4 rounded-full"
          ></button>
          <button
            @click="setColorScheme('light-green')"
            :class="{'border-4 border-blue-500': selectedScheme === 'light-green'}"
            class="w-10 h-10 bg-green-200 border-4 rounded-full"
          ></button>
        </div>
      </div>

      <!-- Language Selection -->
      <div class="mb-4">
        <label for="language" class="block text-gray-700">Language</label>
        <select id="language" v-model="selectedLanguage" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="fr">French</option>
          <option value="de">German</option>
          <option value="hu">Hungarian</option>
        </select>
      </div>

      <!-- Time Zone Selection -->
      <div class="mb-4">
        <label for="timezone" class="block text-gray-700">Time Zone</label>
        <select id="timezone" v-model="selectedTimeZone" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          <option value="UTC-12:00">UTC-12:00</option>
          <option value="UTC-11:00">UTC-11:00</option>
          <option value="UTC-10:00">UTC-10:00</option>
          <option value="UTC-09:00">UTC-09:00</option>
          <option value="UTC-08:00">UTC-08:00</option>
          <option value="UTC-07:00">UTC-07:00</option>
          <option value="UTC-06:00">UTC-06:00</option>
          <option value="UTC-05:00">UTC-05:00</option>
          <option value="UTC-04:00">UTC-04:00</option>
          <option value="UTC-03:00">UTC-03:00</option>
          <option value="UTC-02:00">UTC-02:00</option>
          <option value="UTC-01:00">UTC-01:00</option>
          <option value="UTC+00:00">UTC+00:00</option>
          <option value="UTC+01:00">UTC+01:00</option>
          <option value="UTC+02:00">UTC+02:00</option>
          <option value="UTC+03:00">UTC+03:00</option>
          <option value="UTC+04:00">UTC+04:00</option>
          <option value="UTC+05:00">UTC+05:00</option>
          <option value="UTC+06:00">UTC+06:00</option>
          <option value="UTC+07:00">UTC+07:00</option>
          <option value="UTC+08:00">UTC+08:00</option>
          <option value="UTC+09:00">UTC+09:00</option>
          <option value="UTC+10:00">UTC+10:00</option>
          <option value="UTC+11:00">UTC+11:00</option>
          <option value="UTC+12:00">UTC+12:00</option>
        </select>
      </div>

      <!-- Time Format Selection -->
      <div class="mb-4">
        <label class="block text-gray-700">Time Format</label>
        <div class="flex space-x-2">
          <label class="inline-flex items-center">
            <input type="radio" v-model="timeFormat" value="12h" class="form-radio">
            <span class="ml-2">12-hour</span>
          </label>
          <label class="inline-flex items-center">
            <input type="radio" v-model="timeFormat" value="24h" class="form-radio">
            <span class="ml-2">24-hour</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectedLanguage: 'en',
      selectedTimeZone: 'UTC+00:00',
      timeFormat: '24h',
      selectedScheme: localStorage.getItem('color-scheme') || 'white-black',
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    setColorScheme(scheme) {
      this.selectedScheme = scheme;
      document.documentElement.setAttribute('data-theme', scheme);
      localStorage.setItem('color-scheme', scheme);
      console.log('Color scheme set to:', scheme);
    }
  },
  mounted() {
    this.setColorScheme(this.selectedScheme);
  }
}
</script>
