<template>
  <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full relative">
      <button @click="close" class="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-800">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 class="text-2xl font-semibold mb-4">Settings</h2>

      <div class="mb-4">
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" v-model="emailNotifications" class="sr-only peer">
          <div class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-yellow-400 dark:peer-checked:bg-yellow-500 transition-colors"></div>
          <div class="absolute left-0.5 top-0.5 w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full"></div>
          <span class="ml-2 text-gray-700">Email Notifications</span>
        </label>
      </div>
      <div class="mb-4">
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" v-model="telegramNotifications" class="sr-only peer">
          <div class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-yellow-400 dark:peer-checked:bg-yellow-500 transition-colors"></div>
          <div class="absolute left-0.5 top-0.5 w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full"></div>
          <span class="ml-2 text-gray-700">Telegram Notifications</span>
        </label>
      </div>
      <div class="mb-4">
        <label class="relative inline-flex items-center cursor-pointer">
          <input type="checkbox" v-model="soundEffects" class="sr-only peer">
          <div class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-yellow-400 dark:peer-checked:bg-yellow-500 transition-colors"></div>
          <div class="absolute left-0.5 top-0.5 w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-full"></div>
          <span class="ml-2 text-gray-700">Sound Effects</span>
        </label>
      </div>
      <div class="mb-4">
        <button @click="backupData" class="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-700">Backup All Data</button>
      </div>
      <div class="mb-4">
        <label for="old-password" class="block text-gray-700">Old Password</label>
        <input type="password" id="old-password" v-model="oldPassword" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter old password">
      </div>
      <div class="mb-4">
        <label for="new-password" class="block text-gray-700">New Password</label>
        <input type="password" id="new-password" v-model="newPassword" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Enter new password">
      </div>
      <div class="mb-4">
        <label for="repeat-new-password" class="block text-gray-700">Repeat New Password</label>
        <input type="password" id="repeat-new-password" v-model="repeatNewPassword" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Repeat new password">
      </div>
      <div class="mb-4">
        <button @click="resetPassword" class="bg-blue-500 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-700">Reset Password</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      emailNotifications: false,
      telegramNotifications: false,
      soundEffects: false,
      oldPassword: '',
      newPassword: '',
      repeatNewPassword: ''
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    backupData() {
      // Add your backup logic here
      console.log('Backup all data');
    },
    resetPassword() {
      if (this.newPassword !== this.repeatNewPassword) {
        alert("New passwords do not match!");
        return;
      }
      // Add your password reset logic here
      console.log('Password reset');
    }
  }
}
</script>

<style scoped>
/* Custom styles for the toggle switches */
.relative .peer-checked~div:nth-of-type(2) {
  transform: translateX(100%);
}
</style>
